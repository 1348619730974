
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import EntityModal from "@/components/modals/forms/entity/EntityModal.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default defineComponent({
  name: "kt-widget-6",
  components: {
    EntityModal,
    Datatable,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      lists: [],
      tableData: [],
      
      data: {},
      id:this.$route.params.id,
      entityTypes: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Logo",
          key: "logo",
          sortable: true,
        },
        {
          name: "Short Name",
          key: "short_name",
          sortable: true,
        },
        {
          name: "Full Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Address",
          key: "addpress",
          sortable: true,
        },
        {
          name: "Telephone",
          key: "phone",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Fax",
          key: "fax",
          sortable: true,
        },
        {
          name: "Website",
          key: "website",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
      ],
      formData: {
        entity_type_id: "",
        tranche_id: "",
      },
      showGridView: true,
      showListView: false,
      componentKey: 0,
    };
  },
  async created() {
    await this.getData();
    await this.getTypes();
    await this.filterEntity();
    this.emitter.on("infos-updated", async () => {
      await this.getData();
    });
  },
  methods: {
    async getData() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.lists = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async filterEntity() {
      if(this.id){
        this.formData.entity_type_id= String(this.id) ;
        this.formData.tranche_id="";
      }
      await ApiService.get(
        "entity/infos?entity_type=" +
          this.formData.entity_type_id +
          "&tranche_id=" +
          this.formData.tranche_id
      )
        .then((response) => {
          console.log(response);
          this.lists = response.data;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.emitter.emit("add-modal-data", true);
    },
    gridView() {
      this.showGridView = true;
      this.showListView = false;
    },
    listView() {
      this.showListView = true;
      this.showGridView = false;
    },

    view(infos) {
      this.data = infos;
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("entity/infos/" + `${id}`)
            .then((response) => {
              this.emitter.emit("infos-updated", true);
              Swal.fire("Deleted!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
